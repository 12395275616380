<template>
  <div>
    <SearchHeader v-if="showHeader"/>
    <v-card class="mx-auto rounded-xl ma-xs-0" max-width="800px"
            :loading="this.loading"
            :flat="!this.showHeader"
            outlined
    >
      <v-container class="pa-1 ma-1 fill-height mx-auto" fluid>
        <v-row class="ma-0 pa-0">
          <v-subheader style="height: 16px">
          </v-subheader>
        </v-row>
        <v-row class="ma-1 pa-1">
          <v-flex sm6 xs12 class="pr-sm-3">
            <v-combobox v-if="this.stateList"
                        outlined
                        prepend-inner-icon="mdi-map"
                        v-model="region"
                        rounded
                        :items="this.stateList"
                        item-text="nome"
                        item-value="codigo_uf"
                        label="Onde?"
            ></v-combobox>
          </v-flex>
          <v-flex sm3 xs6>
            <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="startDateText"
                    label="Início"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    rounded
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="startDate"
                  @input="menu1 = false"
                  no-title
                  scrollable
                  rounded
                  :min="new Date().toISOString()"
                  :max="maxDate.toISOString()"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm3 xs6>
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="endDateText"
                    label="Fim"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    rounded
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="endDate"
                  @input="menu2 = false"
                  no-title
                  scrollable
                  :min="minDate.toISOString()"
                  :max="maxDate.toISOString()"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-row>
      </v-container>
    </v-card>
    <v-row class="my-0 py-0">
      <v-col class="my-0 py-0">
        <div class="text-center">
          <v-btn
              :disabled="!region || !startDate || !endDate"
              rounded
              @click="getForecasts"
              color="primary"
              large
              style="margin-top: -50px"
          >
            <v-icon>
              mdi-magnify
            </v-icon>
            Procurar
          </v-btn>
          <v-btn v-if="!this.showHeader"
              class="ml-1"
              rounded
              @click="$router.push({ name: 'donate' })"
              color="secondary"
              large
              style="margin-top: -50px"
          >
            <v-icon>
              mdi-beer
            </v-icon>
            Agradecer
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!--
          <v-divider horizontal class="mx-6" v-if="this.forecasts.length > 0"></v-divider>
    -->
    <v-container v-if="this.$store.state.forecasts.length > 5" class="mt-0 pt-0 mx-auto" fluid>
      <v-card elevation="0" class="mx-auto ma-1 pa-1" max-width="800px" flat>
        <h3>Melhores regiões para acampar</h3>
        <v-subheader class="pl-0">resultados apurados levando em consideração a previsão do tempo desde dia inicial
          até o final
        </v-subheader>
        <v-row id="results" class="fill-height mt-2">
          <v-col>
            <v-card
                class="mx-auto" rounded
                max-width="800" outlined
            >
              <div v-for="f in this.$store.state.forecasts" :key="f.city.codigo_ibge">
                <city-forecast-card-h2 :f="f" :location="location" @onExpansionChange="changeForecastView"/>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ow from "../lib/OpenWeather";
import cities from '../data/cities.json'
import states from '../data/states.json'
import {addDays, format, parseISO, nextSaturday, nextSunday, subDays} from 'date-fns'
import {ptBR} from "date-fns/locale";
import SearchHeader from "@/components/SearchHeader";
import CityForecastCardH2 from "@/components/CityForecastCardH2";
import * as nom from "nominatim-browser"
import {mapActions} from "vuex";

// const openrouteservice = require("openrouteservice-js")

export default {
  components: { CityForecastCardH2, SearchHeader },
  data: () => ({
    promisses: [],
    directionPromisses: [],
    startDate: null,
    endDate: null,
    forecasts: [],
    weather: null,
    show: true,
    region: null,
    stateList: states.data,
    interval: null,
    loading: false,
    menu1: false,
    menu2: false,
    showHeader: true,
    maxDate: null,
    minDate: new Date(),
    location: null,
  }),
  mounted () {
  },
  created () {
    this.getLocation()
    this.minDate = new Date()
    this.maxDate = addDays(new Date(), 7)
    // this.handlePermission()
    let baseDate = subDays(new Date(), 1)
    baseDate = baseDate.setHours(0, 0, 0, 0)

    if (localStorage.getItem('startDate')) {
      // this.startDate =new Date(localStorage.getItem('startDate'))
      // this.endDate = new Date(localStorage.getItem('endDate'))
    }

    if (this.startDate === null ||
        this.endDate === null ||
        (this.endDate > this.maxDate) ||
        this.endDate < this.minDate ||
        this.startDate < baseDate) {
      this.startDate = nextSaturday(baseDate).toISOString()
      this.endDate = nextSunday(nextSaturday(baseDate)).toISOString()
    }
    console.log("start date: ", this.startDate)
    console.log("end date: ", this.endDate)

    this.clearForecasts()
  },
  computed: {
    startDateText () {
      let ret
      if (this.startDate) {
        ret = format(parseISO(this.startDate), 'EEE (d/M)', { locale: ptBR })
      }
      return ret
    },
    endDateText () {
      let ret
      if (this.endDate) {
        ret = format(parseISO(this.endDate), 'EEE (d/M)', { locale: ptBR })
      }
      return ret
    },
  },
  methods: {
    getForecasts () {
      this.saveInterval()
      this.$gtag.event('search', {
        'search_term': this.region.nome
      })
      this.showHeader = false
      this.loading = true
      this.forecasts = []
      // this.clearForecasts()
      // console.log('before sort: ', this.forecasts)
      let citiesFiltered = cities.data
      if (this.region !== null) {
        citiesFiltered = cities.data.filter(el => el.uf === this.region.uf)
      }
      console.log(citiesFiltered)
      citiesFiltered.forEach(this.getForecast)
      const _this = this
      Promise.all(this.promisses).then(() => {
        Promise.all(this.directionPromisses).then(() => {
          _this.$nextTick()
              .then(function () {
                console.log('forecasts now: ', _this.$store.state.forecasts)
                _this.sort()
                _this.$nextTick().then(function () {
                  _this.updateForecasts(_this.forecasts)
                })
                _this.loading = false
                // console.log('setting loaging false')
              })
        })
      })
    },

    filterState (el) {
      this.regions.forEach((r) => {
        console.log('city: ', el)
        console.log('reg: ', r)
        if (r.uf === el.uf) {
          return true
        }
      })
      return false
    },

    getForecast (city) {
      const keys = [
        '96b179a0bc812b9bd4e78ddaa1d9afe4', //julio acampa-me
        // 'c66f67ad87fb9cf6b8f300a8d453349b', //publico
         // '033334acd527011d567b7f10d72192c6', // oprofessor
          '1142971658dcea350c782bdfc2ed8531'  //fernanunes
          // '7a6a73676c3574619b043965ae78fa1d' // prof.silva
      ]
      const key = keys[Math.floor(Math.random() * keys.length)]

      this.promisses.push(ow.getOneCallForecast(city, this.startDate, this.endDate, key)
          .then(data => {
            this.weather = data
            this.weather.city = city
            // console.log('forecast: ', this.weather)
            this.forecasts.push(this.weather)
          })
      )
      // _this.directionPromisses.push(_this.getDirections(city))

    },
    compareScore (a, b) {
      if (a.camp_score > b.camp_score) {
        return -1;
      }
      if (a.camp_score < b.camp_score) {
        return 1;
      }
      return 0;
    },
    sort () {
      const sortedForecasts = this.forecasts.sort(this.compareScore)
      console.log('after sort: ', sortedForecasts)
      // this.updateForecasts(this.forecasts)
    },

    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
    },

    showPosition (position) {
      console.log(position)
      this.location = position
      const query = {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        addressdetails: true
      }
      nom.reverseGeocode(query).then((result) => {
        this.region = this.stateList.find(r => r.nome === result.address.state)
        // console.log(result.address.state)
      })
          .catch((error) => {
            console.error(error);
          });
    },

    changeForecastView (f) {
      // console.log(`cheguei no pai`, f)
      if (f.directions !== undefined) return
      // if (f.directions) return
      // this.getDirections(f.city)
      // this.updateForecasts(this.forecasts)
    },
    saveInterval() {
      localStorage.setItem('startDate', this.startDate)
      localStorage.setItem('endDate', this.endDate)
    },
    ...mapActions(['updateForecasts', 'clearForecasts'])
  }
}
</script>
<style scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
}

@media screen and (min-width: 720px) {
}

</style>
