<template>
  <div class="jsPc6e">
    <div class="DANM4" jsname="P3Vluc">
      <img class="rYD0Re dmJ7hc"
           :src="require('../assets/acampe-me-header.svg')"
           alt="camp header" aria-hidden="true" data-atf="true" data-iml="844">
    </div>
    <div class="main-title">
      <h1>Camping</h1>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchHeader'
}
</script>
<style>
.jsPc6e {
  overflow: hidden;
  padding-bottom: 16px;
  position: relative;
}

@media screen and (min-width: 720px) {
  .DANM4 {
    height: calc(100vw * 0.23339844);
  }
}

.DANM4 {
  max-height: 272px;
  min-height: 128px;
  position: relative;
}

.main-title {
  background-color: rgba(255, 255, 255, 0);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
</style>
