<template>
  <v-chip
      :color="this.getColor()"
      text-color="white"
      x-small
  >
    {{ this.label }}
  </v-chip>
</template>
<script>
export default {
  name: 'ChipCampingScore',
  props: {
    f: {},
    label: {}
  },
  methods: {
    getColor () {
      if (this.f > 80) {
        return 'green'
      }
      else if (this.f > 60) {
        return 'orange'
      }
      else {
        return 'red'
      }
    },
  }
}
</script>
