import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		forecasts: [],
	},
	mutations: {
		['CLEAR_FORECASTS'] (state) {
			state.forecasts = []
		},
		['UPDATE_FORECASTS'] (state, obj) {
			state.forecasts = obj
		}
	},
	actions: {
		updateForecasts ({commit}, obj) {
			commit('UPDATE_FORECASTS', obj)
		},
		clearForecasts({commit}) {
			commit('CLEAR_FORECASTS')
		}
	},
	modules: {
	},
	plugins: [vuexLocal.plugin]
})
