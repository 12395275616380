"use strict";
const axios = require('axios');

/***
 * Clase
 */
class OpenWeather {

	constructor () {
		this.key = null
		this.startDate = null
		this.endDate = null
	}

	/**
	 * @param {string} key
	 */
	setKey (key) {
		this.key = key;
	}

	async getOneCallForecast (city, startDate, endDate) {
		try {
			this.startDate = startDate
			this.endDate = endDate

			const unit = "metric";
			const res = await axios.get(`https://api.openweathermap.org/data/3.0/onecall?exclude=minutely&lat=${ city.latitude }&lon=${ city.longitude }&appid=${ this.key }&lang=pt&units=${ unit }`);
            console.log("Resultado res.data: ", res.data)
            console.log("Resultado..: ", res.data.daily)
			let data = this.normalizeData(res.data);
			data.city = city
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	normalizeData (data) {
        // console.log("normalize in: ", data.daily)
		data.daily.forEach((d) => {
			d.date = new Date((d.dt - 10800) * 1000)
		})
        // console.log("normalize out: ", data.daily)
		data = this.filterForecast(data)
        // console.log("normalize out: ", data.daily)
		return data
	}

	filterForecast (f) {
		// let me = this
		let i = this.getInterval().start
		let e = this.getInterval().end

		if (i > e) {
			let buf = i
			i = e
			e = buf
		}

		let iniDt = new Date(i).getTime() / 1000
		let end = new Date(e)
		end.setDate(end.getDate() + 1);
		let endDt = (end.getTime() / 1000)

		// console.log('datas: ', iniDt, endDt)
		const filterItems = () => {
			return f.daily.filter(el => {
				return (el.dt >= iniDt && el.dt <= endDt)
			})
		}
		f.daily = filterItems()
		// f.daily = f.daily.filter(me.betweenInterval)
		f = this.calculateIndexes(f)
		return f
	}

	getInterval () {
		return {
			start: this.startDate,
			end: this.endDate
		}
	}

	calculateIndexes (f) {
		// console.log('calculating indexes...')
		let totalRain = 0
		let totalPop = 0
		let avgPop = 0
		let counter = 0
		let penalty = 0

		f.daily.forEach((item) => {
			counter++
			if (item.rain !== undefined) {
				// console.log ('calc daily: ' - item)
				totalRain += item.rain
				if ((item.rain) > 2.5) {
					penalty += 2
					penalty += (item.rain - 2) / 1.2
				}
			}
			if (item.wind_gust !== undefined) {
				if ((item.wind_gust) > 20) {
					penalty += 2
					penalty += (item.wind_gust - 20) / 1.2
					f.wind_alert = true
					item.wind_alert = true
				}
			}
			totalPop += item.pop
		})
		avgPop = (totalPop / counter)
		f.total_rain = totalRain
		f.avg_pop = avgPop
		f.rain_score = totalRain * avgPop
		f.camp_score = ((totalRain * avgPop) + penalty) / counter
		if (isNaN(f.camp_score)) {
			// console.warn('NAn', totalRain, avgPop)
		}
		// 0 - 2mm : muito bom
		// 2 - 4: bom
		// 4 - 10: requer preparação
		// 10 - 20 dificil
		// > 20: não recomendado

		if (f.camp_score <= 1) {
			f.camp_score_descrition = 'Excelente'
			f.camp_score = 100 - (f.camp_score * 5)
		} else if (f.camp_score > 1 && f.camp_score <= 2) {
			f.camp_score_descrition = 'Bom'
			f.camp_score = 100 - (f.camp_score * 8)
		} else if (f.camp_score > 2 && f.camp_score <= 3) {
			f.camp_score_descrition = 'Cautela'
			f.camp_score = 100 - (f.camp_score * 10)
		} else if (f.camp_score > 3 && f.camp_score <= 6) {
			f.camp_score_descrition = 'Difícil'
			f.camp_score = 100 - (f.camp_score * 11)
		} else if (f.camp_score > 6) {
			f.camp_score_descrition = 'Não recomendado'
			f.camp_score = 100 - (f.camp_score * 11)
		}

		if (f.camp_score <= 0) {
			f.camp_score = 5
		}

		return f
	}

	betweenInterval (value) {
		// console.log(self.startDate)
		const iniDt = new Date(value.getInterval().start).getTime() / 1000
		const endDt = (new Date(value.getInterval().end).getTime() / 1000)
		console.log('timestamps: ', iniDt, endDt)
		// console.log('object value: ', value)

		return (value.dt >= iniDt && value.dt <= endDt)
	}
}

module.exports = OpenWeather;
