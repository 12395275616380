import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag/dist/vue-gtag"
import store from './store'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(VueGtag, {
	config: {
		id: "G-J562G2MZEM",
//		globalObjectName: 'ga',
		params: {
			send_page_view: true
		}
	}
}, router)

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App)
}).$mount('#app')
