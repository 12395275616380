"use strict";
const OW = require("./OW")
const ow = new OW();

async function getOneCallForecast(city, startDate, endDate, key){
    ow.setKey(key);

    // if(data.cod == '404') return console.log(data.message);
    return await ow.getOneCallForecast(city, startDate, endDate);
}

module.exports =  {getOneCallForecast};
