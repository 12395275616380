<template>
    <v-sheet
        class="mx-auto"
        max-width="300"
    >
      <v-card-title>
        <v-img max-height="50px" max-width="180px"
               :src="require('./assets/acampe.me.svg')" alt="logo we-camp">
        </v-img>
      </v-card-title>
      <v-list rounded>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
          <v-list-item @click="$router.push(item.route)"
              v-for="(item, i) in items"
              :key="i"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
</template>
<script>
export default {
  name: 'app-drawer',
  props: {
  },
  data: () => ({
    selectedItem: 0,
    items: [
      { text: 'Procurar', icon: 'mdi-magnify', route: '/' },
      { text: 'Incentivar', icon: 'mdi-glass-mug-variant', route: '/incentivar' },
      { text: 'Sobre', icon: 'mdi-campfire', route: '/sobre' },
    ]
  })
}
</script>
