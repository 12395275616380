<template>
  <div class="text-center">
    <v-progress-circular
        :rotate="360"
        :width="4"
        :value="campingScore.toFixed()"
        :color="getColor()"
    >
      {{ campingScore.toFixed() }}
    </v-progress-circular>
  </div>
</template>
<script>

export default {
  props: {
    campingScore: { type: Number },
  },
  data: () => ({
    show: true,
  }),
  methods: {
    getColor () {
      if (this.campingScore > 80) {
        return 'green'
      }
      else if (this.campingScore > 60) {
        return 'orange'
      }
      else {
        return 'red'
      }
    },
  }
}
</script>
<style scoped>

</style>
